<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">User View</h6>
        </b-col>
        <b-col lg="6" cols="7" style="display: flex; justify-content: flex-end">
          <base-button @click="back()">Kembali</base-button>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <!-- akun -->
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
              <!-- Subtitle -->
              <h6 class="surtitle">Overview</h6>
              <!-- Title -->
              <h5 class="h3 mb-0">Form Industri</h5>

              <!-- content -->
              <div class="card-body">
                <form>
                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label form-control-label"
                      >Email</label
                    >
                    <div class="col-lg-9">
                      <base-input
                        name="Email"
                        v-model="userInput.email"
                      ></base-input>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label form-control-label"
                      >Password</label
                    >
                    <div class="col-lg-9">
                      <base-input
                        name="Password"
                        type="password"
                        v-model="userInput.password"
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label form-control-label"
                      >Peran</label
                    >
                    <div class="col-lg-9">
                      <base-input class="m-0 mr-4">
                        <el-select
                          filterable
                          placeholder="Peran"
                          v-model="userInput.peran"
                        >
                          <el-option
                            v-for="(option, idx) in peran"
                            :key="idx"
                            :label="option.name"
                            :value="option.name"
                          ></el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label form-control-label"
                      >Provinsi</label
                    >
                    <div class="col-lg-9">
                      <base-input class="m-0 mr-4">
                        <el-select
                          filterable
                          placeholder="Provinsi"
                          v-model="userInput.provinsi"
                          @change="getCityDepentOnProvince(userInput.provinsi)"
                        >
                          <el-option
                            v-for="(option, idx) in provideSelect.provinsi"
                            :key="idx"
                            :label="option.name"
                            :value="option._id"
                          ></el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label form-control-label"
                      >Kab/Kot</label
                    >
                    <div class="col-lg-9">
                      <base-input class="m-0 mr-4">
                        <el-select
                          filterable
                          placeholder="Kab / Kot"
                          v-model="userInput.kabkot"
                        >
                          <el-option
                            v-for="(option, idx) in provideSelect.kabupatenKota"
                            :key="idx"
                            :label="option.name"
                            :value="option.name"
                          ></el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-lg-3 col-form-label form-control-label"
                      >Industri</label
                    >
                    <div class="col-lg-9">
                      <base-input class="m-0 mr-4">
                        <el-select
                          filterable
                          placeholder="Nama Industri"
                          v-model="userInput.industri"
                        >
                          <el-option
                            v-for="(option, idx) in provideSelect.daftarPerusahaan"
                            :key="idx"
                            :label="option.name"
                            :value="option.name"
                          ></el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label
                      class="col-lg-3 col-form-label form-control-label"
                    ></label>
                    <div class="col-lg-9">
                      <base-button type="primary" class="w-25"
                        >Simpan</base-button
                      >
                    </div>
                  </div>
                </form>
              </div>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import API from "../../../api/base_url";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BaseHeader from "@/components/BaseHeader";
import { Select, Option } from "element-ui";

export default {
  components: {
    BaseHeader,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  mounted() {
  },
  data() {
    return {
      userInput: {
        email: "aldila.maretta@pertamina.com",
        password: "admin",
        peran: "pusat",
        provinsi: "Jawa Tengah",
        kabkot: "Kabupaten Cilacap",
        industri: "PT Pertamina (Persero) RU IV Cilacap",
      },
      peran: [{ name: "pusat" }, { name: "provinsi" }],
      provideSelect: {
        provinsi: [],
        kabupatenKota: [],
        daftarPerusahaan: [],
        tmp: [],
        uploadChoosen: null,
      },
    };
  },
  methods: {
    back() {
      this.$router.push("/setting-user");
    },
    
  },
};
</script>
<style></style>
